import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Avatar({ image }) {
  return (
    <div className="wrapImage">
      <GatsbyImage image={image.childImageSharp.gatsbyImageData} className="avatar" loading="eager" alt="lucas">
      </GatsbyImage>
    </div>
  );
}

