import React from "react";
import { graphql } from "gatsby";
import Page from "../components/Page";
import Avatar from "../components/Avatar";
import SocialMedia from "../components/Socialmedia";
import "../styles/about.scss"

export default function About({ data }) {
  return (
    <>
      <Page
        title={"Om Framgångspillret"}
        description="Framgångspillret startades i september 2018 och är idag ett konto för att sprida motivation och inspiration. Framgångspillrets instagram har idag över 10 000 följare."
      >
          <div className="container m-auto max-w-6xl mt-8 mb-12 p-6">

            <h1 className="font-bold text-3xl md:text-4xl max-w-xl text-gray-900 leading-tight mb-4">Om mig</h1>
            <hr />
            <div className="about mt-8">
              <div className="leftAbout">
                <Avatar image={data.avatar} />
                <h4 className="font-bold text-lg text-gray-800">Framgångspillret</h4>
                <SocialMedia />
              </div>
              <div className="rightAbout">
                <p className="mt-4 text-gray-600">Jag som driver Framgångspillret heter Lucas och är 24 år. Jag har ett stort intresse för <b>programmering, personlig utveckling och ekonomi</b>.</p>
                <p className="mt-4 text-gray-600">Jag har alltid läst mycket böcker och att lära mig nya saker och utveckla mig själv har alltid varit något jag brunnit för. Det finns också många sätt att <b>utveckla sig själv</b> på, både fysiskt såväl som mentalt. </p>
                <p className="mt-4 text-gray-600">Om man gör något litet för att förbättra sitt egna liv tror jag också att det kommer leda till att andras liv i din omgivning också förbättras. För vårt handlande influerar också andra i vår omgivning, ofta blir det som <b>en kedje-effekt</b> som bara kan fortsätta och fortsätta sprida sig. Och det är precis det som jag vill åstadkomma med Framgångspillret.</p>
              </div>
            </div>
            <br />
            <br />

          </div>
      </Page>

    </>
  );
}

export const pageQuery = graphql`
  query {
    avatar: file(relativePath: { eq: "avatar.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    }
`;
